import React from 'react'
import { graphql } from 'gatsby'
import PageBuilder from '../components/pageBuilder'
import SEO from '../components/seo'

export const query = graphql`
  query ImprintDatenschutzPageQuery {
    imprintDatenschutzPage: sanityPage(slug: { current: { eq: "imprint-datenschutz" } }) {
      id
      title
      ...PageBuilder
    }
  }
`

const ImprintDatenschutzPage = props => {
  const { data, errors } = props
  const pageData = data && data.imprintDatenschutzPage
  return (
    <>
      {pageData && pageData.title && <SEO title={pageData.title} />}
      {pageData && (
        <PageBuilder
          pageBuilder={pageData.pageBuilder}
          _rawPageBuilder={pageData._rawPageBuilder}
        />
      )}
    </>
  )
}

export default ImprintDatenschutzPage
